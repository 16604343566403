import mutations from './mutations';

describe('mutations', () => {
  describe('setSystemTimeInfo', () => {
    it('should set system capability', () => {
      const state = {};
      mutations.setSystemCapability(state, { test: true });
      expect(state.capability).toEqual({ test: true });
    });
    it('should set system time ans timezone', () => {
      const state = { timestamp: 0, timezone: 0 };

      mutations.setSystemTimeInfo(state, { timestamp: 1000000, timezone: -28800 });

      expect(state.timeInfo).toEqual({ timestamp: 1000000, timezone: -28800 });
    });
  });
});
